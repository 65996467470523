<template>
  <div class="reset-password">
    <svg
      class="blob-1"
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="100%"
      id="blobSvg"
    >
      <defs>
        <linearGradient id="gradient-1" x1="60%" y1="0%" x2="0%" y2="30%">
          <stop offset="0%" style="stop-color: rgb(209, 216, 224)"></stop>
          <stop offset="100%" style="stop-color: rgb(193, 199, 245)"></stop>
        </linearGradient>
      </defs>
      <path
        id="blob"
        d="M425.5,342.5Q357,435,261,416.5Q165,398,92,324Q19,250,78,152Q137,54,242,68Q347,82,420.5,166Q494,250,425.5,342.5Z"
        fill="url(#gradient-1)"
      ></path>
    </svg>

    <svg
      class="blob-2"
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="100%"
      id="blobSvg"
    >
      <defs>
        <linearGradient id="gradient-2" x1="0%" y1="60%" x2="30%" y2="100%">
          <stop offset="0%" style="stop-color: rgb(209, 216, 224)"></stop>
          <stop offset="100%" style="stop-color: rgb(148, 156, 223)"></stop>
        </linearGradient>
      </defs>
      <path
        id="blob"
        d="M387,315.5Q326,381,228,420Q130,459,69.5,354.5Q9,250,83,169.5Q157,89,258.5,74Q360,59,404,154.5Q448,250,387,315.5Z"
        fill="url(#gradient-2)"
      ></path>
    </svg>

    <div class="card p-4">
      <h2 class="my-3">Forgot Password?</h2>

      <div class="desc">
        <h4 class="mb-1">Don't worry.</h4>
        <p>
          Enter the email associated with your account. We will email you a link
          to reset your password.
        </p>
      </div>

      <div class="d-flex justify-content-center">
        <img
          class="forgot-password-pic"
          :src="forgotPasswordSVG"
          alt="No Data"
        />
      </div>

      <fd-form class="mt-2" @submit="submitForm">
        <div class="row">
          <fd-input
            v-model="email"
            class="col-12 mb-5"
            label="Email"
            name="ResetPasswordEmail"
            type="text"
            :validators="[validator.email, validator.required]"
          >
          </fd-input>
          <button class="btn main lg col-12">Continue</button>
        </div>

        <p class="to-login">
          Remembered your password now?
          <router-link :to="{ name: 'Login' }">Login</router-link>
        </p>
      </fd-form>
    </div>
  </div>
</template>

<script>
import forgotPasswordSVG from "@/assets/image/illustration/forgot_password.svg";
import {
  required,
  email
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { account as accAPI } from "@/api";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      email: "",

      validator: {
        required: required,
        email: email
      },

      forgotPasswordSVG: forgotPasswordSVG
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);
        await accAPI.forgetPassword(this.email);

        this.$confirm({
          title: "Your request has been sent successfully.",
          message:
            "If you do not see the password reset email in your inbox, please check the junk/spam folder.",
          confirmText: "OK"
        }).then((confirm) => {
          if (confirm) {
            this.$router.push({ name: "Login" });
          }
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: error.response.data.message
        });

        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .card {
    z-index: 1;
    min-height: 100vh;
    @media #{$breakpoint-up-sm} {
      max-width: 600px;
      min-height: initial;
    }
  }
  .desc {
    border-radius: 4px;
    margin: 10px 0;
  }
  .forgot-password-pic {
    width: 120px;
    height: 120px;
    margin: 15px 0;
  }

  .to-login {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
  }

  .blob-1 {
    position: absolute;
    width: 500px;
    left: 10vw;
    top: 10vh;
    @media #{$breakpoint-down-md} {
      display: none;
    }
  }
  .blob-2 {
    position: absolute;
    width: 500px;
    right: 10vw;
    bottom: 10vh;
    @media #{$breakpoint-down-md} {
      display: none;
    }
  }
}
</style>
